import React, { useState } from "react";
import "./Volume.css";

function Volume() {
	const [isVolumeOn, setIsVolumeOn] = useState(false);

	const toggleVolume = () => {
		setIsVolumeOn(!isVolumeOn);
	};

	return (
		<div className="volume-icon-container" >
			{isVolumeOn ?
				<img className="volume-on-icon" onClick={toggleVolume} src="icons/volume-on.png" alt="Volume On" />
				:
				<img className="volume-off-icon" onClick={toggleVolume} src="icons/volume-off.png" alt="Volume Off" />
			}
		</div>
	);
}

export default Volume;
