import React, { useEffect, useRef, useState } from "react";

import "./App.css";

import IconDisplay from "./components/IconDisplay";
import Clock from "./components/Clock";
import TaskBarAppDisplay from "./components/TaskBarAppDisplay";
import Volume from "./components/Volume";

import Terminal from "./apps/Terminal/Terminal";
import Txt from "./apps/Txt/Txt";
import MyPc from "./apps/MyPc/MyPc";

import { useAppsManager } from "./context/AppsManagerContext";

function App() {
	const { openedApps } = useAppsManager();

	const appsDisplayParentRef = useRef(null);
	const appsTaskBarParentRef = useRef(null);

	const terminalRef = useRef(null);
	const txtRef = useRef(null);
	const myPcRef = useRef(null);

	const [isTerminalOpened, setIsTerminalOpened] = useState(false);
	const [isTxtOpened, setIsTxtOpened] = useState(false);
	const [isMyPcOpened, setIsMyPcOpened] = useState(false);

	const [isScreenTurnedOn, setIsScreenTurnedOn] = useState(true);

	const appsStateMap = {
		termainal: { ref: terminalRef, isOpened: isTerminalOpened, setIsOpened: setIsTerminalOpened },
		txt: { ref: txtRef, isOpened: isTxtOpened, setIsOpened: setIsTxtOpened },
		mypc: { ref: myPcRef, isOpened: isMyPcOpened, setIsOpened: setIsMyPcOpened },
	};

	useEffect(() => {
		const registeredStateListeners = [];

		for (const [key, { ref, setIsOpened }] of Object.entries(appsStateMap)) {
			if (ref?.current) {
				registeredStateListeners.push(
					ref.current.onAppCoreOpenStateChanged((isOpened) => {
						setIsOpened(isOpened);
					})
				);
			}
		}

		return () => {
			registeredStateListeners.forEach((removeListener) => removeListener());
		};
	}, [terminalRef, txtRef, myPcRef, isScreenTurnedOn]);

	const handleToggleScreen = () => {
		setIsScreenTurnedOn((prevState) => !prevState);
	};

	return (
		<div className="main">
			{isScreenTurnedOn && (
				<div className="lock-computer-crt-container">
					<img className="glitch-screen-effect" src="icons/glitch.gif"  alt="glitch" />
					<div className="out-light"/>
					<div className="shadow" />
					<div className="lock-computer-screen-container" ref={appsDisplayParentRef}>
						{isTerminalOpened ? terminalRef?.current?.render() : null}
						{isTxtOpened ? txtRef?.current?.render() : null}
						{isMyPcOpened ? myPcRef?.current?.render() : null}
					</div>

					<div className="computer-taskbar-container" ref={appsTaskBarParentRef}>
						{openedApps.map((appRef) => {
							return <TaskBarAppDisplay appRef={appRef} />;
						})}
					</div>
				</div>
			)}

			<div className="computer-crt-container">
				<div className="computer-screen-container">
					{isScreenTurnedOn ?
						<div className="computer-screen" style={{position:"relative"}}>
							<img src="icons/taskbar.jpeg" style={{ width: "100%", height: "min-content", overflow: "hidden", position: "absolute", bottom: "0px" }} />
							{/*<img src="icons/bg.png" style={{ width: "100%", height: "100%", overflow: "hidden" }} />*/}

							<div className="computer-screen-icons-container" style={{ position: "absolute", width: "800px", height: "600px" }}>
								{/* {apps.map((app) => app.makeIcon())} */}
								<IconDisplay icon="icons/explorer.png" title="internet" x={0} y={0} />
								<IconDisplay icon="icons/bin.png" title="trash" x={14.5} y={6} />
								<IconDisplay icon="icons/windows-folder.png" title="my-projects" x={14.5} y={2} />
								<IconDisplay icon="icons/github.png" title="github" x={0} y={1} href="https://github.com/ezylpro" />
								<IconDisplay icon="icons/linkedin.png" title="linkedin" x={0} y={2} href="https://www.linkedin.com/in/ezylpro/" />
								<IconDisplay icon="icons/txt.png" title="curriculum.txt" x={3} y={3} href="./Curriculum.pdf" />

								<Terminal iconX={14.5} iconY={0} ref={terminalRef} parentRef={appsDisplayParentRef} />
								{/*<Txt iconX={3} iconY={3} ref={txtRef} parentRef={appsDisplayParentRef} />*/}
								<MyPc iconX={14.5} iconY={1} ref={myPcRef} parentRef={appsDisplayParentRef} />
							</div>
							<Clock />
							<Volume />
						</div>
					 :
						<div className="computer-screen" style={{backgroundColor:"black"}} />
					}
				</div>
				<div className="computer-button-container">
					<div className="computer-button-light" style={isScreenTurnedOn ? {} : {backgroundColor: "red", boxShadow:"0px 0px 25px 1px red"}}></div>
					<button className="computer-button" onClick={handleToggleScreen}></button>
				</div>
			</div>

			<div className="computer-stand"></div>

			<div className="computer-case">
				<div className="computer-diskhat-container" style={{ height: "100%", width: "100%" }}>
					<div className="computer-diskhat">
						<div className="computer-diskhat-input-container">
							<div className="computer-diskhat-input">
								<div className="computer-diskhat-input-2"></div>
							</div>

							<div className="computer-diskhat-button-container" style={{ height: "100px", width: "300px", position: "absolute" }}>
								<div className="computer-diskhat-button"></div>
							</div>
						</div>
					</div>
				</div>

				<div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", paddingTop: "30px" }}>
					<div className="computer-turn-button-container">
						<button className="computer-turn-button"></button>
					</div>

					<div className="computer-case-fans-container">
						<img src="icons/fans.png" style={{ width: "279px", height: "80px" }} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default App;
