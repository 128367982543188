import "./MyPcConfigRenderer.css";
import "../MyPcRenderer.css";
import Button from "../../../components/Button";

function MyPcConfigRenderer({ appRef }) {
	return (
		<div className="my-pc-renderer-container">
			<div className="my-pc-renderer-title-container">
				<a className="my-pc-renderer-title">ABOUT ME</a>
			</div>
			<div className="my-pc-renderer-container-2">
				<div className="my-pc-info-father-container">
					<div className="my-pc-info-container">
						<div>
							<img src="icons/selfie.jpg" style={{ width: "140px", height: "140px" }}></img>
						</div>
						<div className="my-pc-title2-container">
							<a className="my-pc-title">Bruno Fischer</a>
							<a>Software Engineer</a>
						</div>
					</div>
					<div className="my-pc-info-container-2">
						<div className="my-pc-title2-container">
							<a className="my-pc-title-2">Stacks:</a>
							<a>PHP/Laravel, Typescript/Javascript, SQL, AWS, Docker</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default MyPcConfigRenderer;
