import "./MyPcSetupRenderer.css";
import "../MyPcRenderer.css"

function MyPcSetupRenderer({ appRef }) {
    return(
        <div className="my-pc-renderer-container">
            <h1>Config</h1>

        </div>
    )
}

export default MyPcSetupRenderer;

